export const EXPERIENCES = [
  {
    from: "Feb 2023",
    to: "Present",
    companyName: "Holycode (Neon)",
    role: "Angular Developer",
    description:
      "Develop and uphold crucial components integral to Neon's frontend, spanning the entire product. Collaborate closely with cross-functional teams, encompassing developers, testers, designers, and product managers, to translate design concepts and backend functionalities into frontend code.",
    technologies: [
      "Angular",
      "Ionic",
      "Cordova",
      "SCSS",
      "TypeScript",
      "JavaScript",
      "HTML",
    ],
    linkUrl: "https://www.holycode.com/",
  },
  {
    from: "Aug 2022",
    to: "Feb 2023",
    companyName: "Holycode (Neon)",
    role: "Angular Developer Internship",
    description:
      "Built practical skills with Angular, Ionic, and Cordova, actively contributing to cross-platform app development. Conducted research on migrating from Cordova to Capacitor during my internship. Proficient in Jira for project management, Bitbucket for version control, and various git commands for collaborative development.",
    technologies: [
      "Angular",
      "Ionic",
      "Capacitor",
      "SCSS",
      "TypeScript",
      "JavaScript",
      "HTML",
    ],
    linkUrl: "https://www.holycode.com/",
  },
];
